import React from "react";

const Hryvna = () => {
  return (
    <svg
      height="33px"
      viewBox="0 0 512 512"
      width="33px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m256 0c-140.699219 0-256 115.300781-256 256s115.300781 256 256 256 256-115.300781 256-256-115.300781-256-256-256zm0 0"
        fill="#fdbf00"
      />
      <path
        d="m512 256c0 140.699219-115.300781 256-256 256v-512c140.699219 0 256 115.300781 256 256zm0 0"
        fill="#ff9100"
      />
      <path
        d="m256 61c-107.402344 0-195 87.597656-195 195 0 107.398438 87.597656 195 195 195s195-87.601562 195-195c0-107.402344-87.597656-195-195-195zm0 0"
        fill="#ffda2d"
      />
      <path
        d="m451 256c0 107.398438-87.597656 195-195 195v-390c107.402344 0 195 87.597656 195 195zm0 0"
        fill="#fdbf00"
      />
      <path
        d="m261.398438 271h99.601562v30h-136.800781c-6.601563 5.097656-12.597657 9.898438-18 14.097656-8.699219 6.601563-11.699219 13.203125-9.597657 19.800782 4.496094 12.601562 26.699219 26.101562 59.398438 26.101562 24 0 48.898438-9.300781 57-21l24.898438 17.101562c-16.199219 23.398438-52.800782 33.898438-81.898438 33.898438-43.199219 0-78.300781-18.601562-87.597656-46.5-5.101563-15-1.5-30.601562 9.597656-43.5h-27v-30h62.699219c12-9.601562 24.601562-19.800781 36.902343-30h-99.601562v-30h136.800781c6.898438-5.398438 13.199219-10.5 17.699219-13.800781 8.699219-6.597657 12-13.199219 9.898438-20.097657-4.199219-12.601562-26.398438-26.101562-59.398438-26.101562-24 0-48.898438 9.300781-57 21l-24.597656-17.101562c15.898437-23.398438 52.5-33.898438 81.597656-33.898438 43.199219 0 78.601562 18.898438 87.898438 46.800781 5.101562 15.300781 1.199218 30.300781-9.898438 43.199219h27v30h-62.699219c-21.449219 17.011719-29.855469 24.363281-36.902343 30zm0 0"
        fill="#ff9100"
      />
      <g fill="#ff641a">
        <path d="m313 340 24.898438 17.101562c-16.199219 23.398438-52.800782 33.898438-81.898438 33.898438v-30c24 0 48.898438-9.300781 57-21zm0 0" />
        <path d="m261.398438 271h99.601562v30h-105v-90h31.800781c6.898438-5.398438 13.199219-10.5 17.699219-13.800781 8.699219-6.597657 12-13.199219 9.898438-20.097657-4.199219-12.601562-26.398438-26.101562-59.398438-26.101562v-30c43.199219 0 78.601562 18.898438 87.898438 46.800781 5.101562 15.300781 1.199218 30.300781-9.898438 43.199219h27v30h-62.699219c-21.449219 17.011719-29.855469 24.363281-36.902343 30zm0 0" />
      </g>
    </svg>
  );
};

export default Hryvna;
