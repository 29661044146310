import React from "react";
import ReactDOM from "react-dom";
import Home from "./pages/Home/Home";
import Success from "./pages/Success/Success";
import "./App.css";
import * as serviceWorker from "./serviceWorker";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/success" component={Success} />
        <Route path="/" exact component={Home} />
        <Redirect to="/" />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// https://mega.exchange/success?id=cpi_ZcuXkrmBNMkVQ55q&serial_number=ZcuXkrmBNMkVQ55q&amount=12.08&currency=UAH&status=process_pending&resolution=OK&reference_id=7od6PsM4qPfb&description=KUNA.io%3A+Deposit+account+UAH&test_mode=0&created=1593198191&service=payment_card_uah_hpp&service_method=payment_card&service_currency=UAH
