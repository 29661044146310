import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import qs from "qs";
// import crypto from "crypto";

import Header from "../../components/Header/Header";
import axios from "axios";
import styled from "styled-components";
import "styled-components/macro";

import * as api from "../../entryPoints";
// import { apiKey, apiSecret } from "../../config";

import SuccessIcon from "../../assets/icons/Success";
import pending from "../../assets/icons/pending.png";
import canceled from "../../assets/icons/canceled.png";

import Loader from "../../components/Loader/Loader";
import Panel from "../../components/UI/Panel/Panel";
import Flexbox from "../../components/UI/Flexbox/Flexbox";

const Container = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 10px;
`;

const LinkStyled = styled(Link)`
  width: 180px;
  text-align: center;
  display: block;
  margin: 0 auto;
  text-decoration: none;
  background-color: #2e71f0;
  border-radius: 20px;
  color: #fff;
  padding: 20px 10px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease 0s;
  overflow: hidden;

  &:hover {
    background-color: #044cd7;
  }
`;

const StatusContainer = styled(Flexbox).attrs({
  column: true,
  alignItems: "center",
})`
  min-height: 180px;
  & > p {
    margin: 10px 0 45px 0;
  }
`;

const Success = () => {
  const {
    replace,
    location: { search },
  } = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [deposit, setDeposit] = useState({});
  const [isError, setIsError] = useState(false);

  const parsed = useMemo(() => qs.parse(search.replace("?", "")), [search]);

  useEffect(() => {
    if (!parsed.reference_id) replace("/");
    if (parsed.reference_id)
      replace(`/success?reference_id=${parsed.reference_id}`);
  }, [parsed.reference_id, replace]);

  useEffect(() => {
    setIsLoading(true);

    // const apiPath = "/v3/auth/deposit/details";
    // const nonce = Date.now().toString();
    // const body = {
    //   id: parsed.reference_id,
    // };

    // let signature = `${apiPath}${nonce}${JSON.stringify(body)}`;
    // const sig = crypto.createHmac("sha384", apiSecret).update(signature);
    // const shex = sig.digest("hex");

    // return axios
    //   .post(`https://api.kuna.io${apiPath}`, body, {
    //     headers: {
    //       "kun-nonce": nonce,
    //       "kun-apikey": apiKey,
    //       "kun-signature": shex,
    //     },
    //   })
    //   .then((resp) => {
    //     setDeposit(resp.data);
    //   })
    //   .catch(() => setIsError(true))
    //   .finally(() => setIsLoading(false));

    axios
      .get(api.url.getNewDepositInfo(parsed.reference_id))
      .then((resp) => setDeposit(resp.data.info))
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false));
  }, [parsed.reference_id]);

  return (
    <Container>
      <Panel
        alignItems="center"
        relative
        css={`
          max-width: 550px;
          width: 100%;
        `}
      >
        {isLoading && <Loader />}
        <Header></Header>

        <StatusContainer>
          {isError && (
            <>
              <h1>Ошибка</h1>
              <p>Платеж не найден</p>
              <LinkStyled to="/">На главную</LinkStyled>
            </>
          )}

          {Object.keys(deposit).length > 0 && (
            <>
              {deposit.status === "done" && (
                <>
                  <div>
                    <SuccessIcon />
                  </div>
                  <h1>Успешно!</h1>
                </>
              )}

              {(deposit.status === "process_pending" ||
                deposit.status === "pending") && (
                <>
                  <div>
                    <img src={pending} alt="pending" width="54px" />
                  </div>
                  <h1>Ожидается оплата</h1>
                </>
              )}

              {deposit.status === "canceled" && (
                <>
                  <div>
                    <img src={canceled} alt="canceled" width="54px" />
                  </div>
                  <h1>Отменено</h1>
                </>
              )}
              <p>
                Сумма: <span>{deposit.amount} грн</span>
              </p>
              <LinkStyled to="/">На главную</LinkStyled>
            </>
          )}
        </StatusContainer>
      </Panel>
    </Container>
  );
};

export default Success;
