const apiBaseUrlProd = "https://pixel.black/api/v1";
const apiBaseUrlDev = "http://localhost:5050/api/v1";

const isDev = process.env.NODE_ENV !== "production";

const apiBaseUrl = isDev ? apiBaseUrlDev : apiBaseUrlProd;

module.exports = {
  apiBaseUrl,
  apiKey: "rgNA8ziCvP1RWUeggcBALgYLn7S1FcFScuTJhfws",
  apiSecret: "lNAtMtySlBWeLQXDb811xPMArQdngHwxTZoRtMMS",
};
