import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
// import crypto from "crypto";
import s from "./Form.module.css";
import Hryvna from "../../assets/icons/Hryvna";
import * as api from "../../entryPoints";

// Components
// import Input from "../UI/Input/Input";
// import { apiSecret, apiKey } from "../../config";

const focusedClass = s["input_container-focused"];
const invalidClass = s["input_container-invalid"];

const maxValueError = "Максимальная сумма 24999грн";
const requiredError = "Сумма обязательна";
const minimumError = "Минимальная сумма 10грн";

const maxMessage = "Максимальное кол. символов 30";
const onlyLeters = "Только буквы и цифры";

const Form = ({ setIsLoading }) => {
  const [amount, setAmount] = useState("");
  const [payedAmount, setPayedAmount] = useState("");

  const [containerClasses, setContainerClasses] = useState([s.input_container]);
  const [errors, setErrors] = useState([]);
  const [requestError, setRequestError] = useState(false);

  const [isMessage, setisMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState([]);
  const [messageClasses, setMessageClasses] = useState([s.input_container]);

  const deleteClass = (className, input) => {
    switch (input) {
      case "amount":
        setContainerClasses((prev) => prev.filter((el) => el !== className));
        break;
      case "message":
        setMessageClasses((prev) => prev.filter((el) => el !== className));
        break;

      default:
        break;
    }
  };

  const addClass = (className, input) => {
    switch (input) {
      case "amount":
        if (!containerClasses.includes(className)) {
          setContainerClasses((prev) => [...prev, className]);
        }
        break;

      case "message":
        if (!messageClasses.includes(className)) {
          setMessageClasses((prev) => [...prev, className]);
        }
        break;
      default:
        break;
    }
  };

  const addError = (message, input) => {
    switch (input) {
      case "amount":
        if (!errors.includes(message)) {
          setErrors((prev) => [...prev, message]);
        }
        break;
      case "message":
        if (!messageError.includes(message)) {
          setMessageError((prev) => [...prev, message]);
        }
        break;
      default:
        break;
    }
  };

  const removeError = (message, input) => {
    switch (input) {
      case "amount":
        setErrors((prev) => prev.filter((el) => el !== message));
        break;
      case "message":
        setMessageError((prev) => prev.filter((el) => el !== message));
        break;
      default:
        break;
    }
  };

  const handleInput = (e) => {
    const { value } = e.target;

    if (parseInt(value) < 0 || value.length > 7) {
      return;
    }

    if (!value) {
      addError(requiredError, "amount");
    } else {
      removeError(requiredError, "amount");
    }

    if (parseInt(value) < 10) {
      addError(minimumError, "amount");
    } else {
      removeError(minimumError, "amount");
    }

    if (parseInt(value) > 24999) {
      addError(maxValueError, "amount");
    } else {
      removeError(maxValueError, "amount");
    }
    setPayedAmount((value * 1.015 + 5).toFixed(2));

    // if (value.includes(".")) return false;
    setAmount(value);
  };

  const handleMessage = (e) => {
    const { value } = e.target;
    const reg = /^[A-zА-я0-9 _]*[A-zА-я0-9][A-zА-я0-9 _]*$/i;

    if (value.length > 34) return;

    if (value.length > 30) {
      addError(maxMessage, "message");
    } else {
      removeError(maxMessage, "message");
    }

    if (value && !reg.test(value)) {
      addError(onlyLeters, "message");
    } else {
      removeError(onlyLeters, "message");
    }

    setMessage(value);
  };

  const handleSumbit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setRequestError(false);

    return axios
      .post(api.url.getNewDeposit(), { amount, message })
      .then((resp) => {
        const { url } = resp.data;
        if (url) {
          return window.open(url, "_self");
        }
        throw new Error();
      })
      .catch(() => setRequestError(true))
      .finally(() => setIsLoading(false));
  };

  const handleFocus = (e) => {
    const { name } = e.target;
    switch (name) {
      case "amount":
        return addClass(focusedClass, "amount");
      case "message":
        return addClass(focusedClass, "message");
      default:
        break;
    }
  };

  const onBlur = (e) => {
    const { name } = e.target;
    switch (name) {
      case "amount":
        deleteClass(focusedClass, "amount");
        if (!amount) {
          addError(requiredError, "amount");
        }
        break;
      case "message":
        deleteClass(focusedClass, "message");

        break;
      default:
        break;
    }
  };

  const isDisabled = useMemo(() => {
    if (errors.length || !amount || messageError.length) return true;

    return false;
  }, [amount, errors.length, messageError.length]);

  useEffect(() => {
    if (errors.length) {
      addClass(invalidClass, "amount");
    } else {
      deleteClass(invalidClass, "amount");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (messageError.length) {
      addClass(invalidClass, "message");
    } else {
      deleteClass(invalidClass, "message");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageError]);

  return (
    <>
      <form onSubmit={handleSumbit} className={s.form}>
        <div className={s.form_controll}>
          <div className={containerClasses.join(" ")}>
            <input
              type="number"
              name="amount"
              value={amount}
              onChange={handleInput}
              className={s.amount}
              placeholder="Сумма"
              onFocus={handleFocus}
              onBlur={onBlur}
              step="0.01"
              size="10"
              required
            />
            <div className={s.hrn_container}>
              <Hryvna />
            </div>
          </div>

          {errors.length > 0 && (
            <div className={s.error_container}>
              {errors.map((err) => (
                <p key={err} className={s.error}>
                  {err}
                </p>
              ))}
            </div>
          )}
          {payedAmount && (
            <p className={s.payedAmount}>
              С учетом комисии <span>{payedAmount}</span> грн
            </p>
          )}
        </div>

        {isMessage ? (
          <div className={s.form_controll}>
            <div className={messageClasses.join(" ")}>
              <input
                type="text"
                name="message"
                value={message}
                onChange={handleMessage}
                className={s.amount}
                placeholder="Коментарий"
                onFocus={handleFocus}
                onBlur={onBlur}
              />
            </div>

            {messageError.length > 0 && (
              <div className={s.error_container}>
                {messageError.map((err) => (
                  <p key={err} className={s.error}>
                    {err}
                  </p>
                ))}
              </div>
            )}
          </div>
        ) : (
          <button
            className={s.comment_btn}
            type="button"
            onClick={() => setisMessage(true)}
          >
            Добавить коментарий
          </button>
        )}

        <button type="submit" className={s.submit_btn} disabled={isDisabled}>
          {`Оплатить ${amount && `${amount} грн`}`}
        </button>
        {requestError && (
          <p className={s.error}>Ошибка! Попробовать еще раз? </p>
        )}
      </form>
    </>
  );
};

Form.propTypes = {};

export default Form;
