import styled, { css } from "styled-components";

export default styled.div`
  display: ${({ inline }) => (inline ? "inline-flex" : "flex")};

  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `};

    ${({ justify }) =>
      justify &&
      css`
        justify-content: ${justify};
      `}

    ${({ alignItems }) =>
      alignItems &&
      css`
        align-items: ${alignItems};
      `}

    ${({ fullWidth }) =>
      fullWidth &&
      css`
        width: 100%;
      `}

    ${({ fullHeight }) =>
      fullHeight &&
      css`
        height: 100%;
      `}

    ${({ flexWrap }) =>
      flexWrap &&
      css`
        flex-wrap: wrap;
      `}

    ${({ relative }) =>
      relative &&
      css`
        position: relative;
      `};
`;
