import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Form from "../../components/Form/Form";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";
import { Container } from "./Styled";
import Panel from "../../components/UI/Panel/Panel";
import "styled-components/macro";

const App = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Container>
      <Panel
        css={`
          margin-bottom: 20px;
        `}
        relative
      >
        {isLoading && <Loader />}
        <Header />
        <Form setIsLoading={setIsLoading} />
      </Panel>
      <Footer></Footer>
    </Container>
  );
};

export default App;
