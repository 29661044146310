import React from "react";

const Success = ({ width = "54", height = "54" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52 24.7143V27.0143C51.9969 32.4054 50.2512 37.651 47.0233 41.9689C43.7954 46.2867 39.2582 49.4455 34.0884 50.974C28.9186 52.5026 23.3931 52.319 18.3362 50.4508C13.2792 48.5825 8.96163 45.1295 6.0274 40.607C3.09317 36.0844 1.69948 30.7345 2.05419 25.3551C2.40891 19.9757 4.49302 14.8551 7.9957 10.757C11.4984 6.65886 16.232 3.80276 21.4905 2.61467C26.749 1.42658 32.2507 1.97015 37.175 4.16431"
        stroke="#039B7B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52 7.01562L27 32.0406L19.5 24.5406"
        stroke="#039B7B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Success;
