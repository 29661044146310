import React from "react";

const SSL = () => {
  return (
    <svg
      width="65"
      height="32"
      viewBox="0 0 65 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M0.289062 31.6467H22.464V15.9765H0.289062V31.6467ZM8.13339 22.5849L10.224 24.6802L15.0664 19.8269L16.621 21.385L10.224 27.7963L6.57882 24.1429L8.13339 22.5849ZM6.82898 9.02793C6.82898 6.5207 8.866 4.4791 11.3855 4.4791C13.8871 4.4791 15.9241 6.5207 15.9241 9.02793V13.7379H19.6765V9.02793C19.6586 4.44328 15.942 0.718262 11.3855 0.718262C6.81111 0.718262 3.09444 4.44328 3.09444 9.02793V13.7379H6.84684V9.02793H6.82898ZM40.6364 14.6155C40.6364 12.359 39.3141 11.0695 37.2235 10.2815L35.2937 9.45774C33.757 8.90257 32.6849 8.52648 32.6849 7.57732C32.6849 6.64606 33.4889 6.12671 34.6861 6.12671C36.2407 6.12671 37.2414 6.59234 38.3492 7.39823L40.1718 5.12382C38.7245 3.76275 36.8125 3.02849 34.8827 3.02849C31.4519 3.02849 29.0575 5.12382 29.0575 7.77431C29.0575 10.1025 30.6657 11.4635 32.4526 12.1261L34.5075 13.0037C36.0263 13.6305 36.9733 13.9349 36.9733 15.0632C36.9733 15.8691 36.2586 16.4601 34.7398 16.4601C33.2031 16.4601 31.72 15.7795 30.5228 14.7945L28.45 17.266C30.1475 18.7882 32.3454 19.5583 34.4896 19.5583C38.4207 19.5583 40.6364 17.2481 40.6364 14.6155ZM49.4099 10.2815L47.4801 9.45774C45.9434 8.90257 44.8713 8.52648 44.8713 7.57732C44.8713 6.64606 45.6754 6.12671 46.8725 6.12671C48.4271 6.12671 49.4278 6.59234 50.5356 7.39823L52.3582 5.12382C50.9109 3.76275 48.9989 3.02849 47.0691 3.02849C43.6383 3.02849 41.2439 5.12382 41.2439 7.77431C41.2439 10.1025 42.8521 11.4635 44.639 12.1261L46.6939 13.0037C48.2127 13.6305 49.1597 13.9349 49.1597 15.0632C49.1597 15.8691 48.445 16.4601 46.9262 16.4601C45.3895 16.4601 43.9064 15.7795 42.7092 14.7945L40.6364 17.266C42.3339 18.7882 44.5318 19.5583 46.676 19.5583C50.625 19.5583 52.8407 17.266 52.8407 14.6155C52.8228 12.359 51.5184 11.0695 49.4099 10.2815Z"
          fill="#93A9C3"
        ></path>{" "}
        <path
          d="M57.7725 16.2273V3.31506H54.1987V19.2539H64.8484V16.2273H57.7725Z"
          fill="#93A9C3"
        ></path>{" "}
        <path
          d="M31.827 24.913L30.8621 24.5011C30.2724 24.2504 29.6291 24.0176 29.6291 23.355C29.6291 22.7282 30.1652 22.3521 30.9693 22.3521C31.684 22.3521 32.2022 22.6028 32.6668 22.9968L33.1314 22.4237C32.6311 21.9223 31.8806 21.5641 31.005 21.5641C29.7006 21.5641 28.7536 22.3342 28.7536 23.3908C28.7536 24.3937 29.5577 24.8951 30.2367 25.1637L31.2194 25.5936C31.9163 25.898 32.4524 26.1129 32.4524 26.8472C32.4524 27.4919 31.8984 27.9396 30.9514 27.9396C30.1652 27.9396 29.4504 27.5993 28.9323 27.08L28.4141 27.6889C29.0395 28.3157 29.8972 28.7097 30.9157 28.7097C32.4345 28.7097 33.3458 27.8322 33.3458 26.7397C33.3458 25.7189 32.6668 25.2533 31.827 24.913Z"
          fill="#93A9C3"
        ></path>{" "}
        <path
          d="M36.0797 25.3428H38.9386V24.5907H36.0797V22.4237H39.4568V21.6895H35.2041V28.5843H39.564V27.8322H36.0797V25.3428Z"
          fill="#93A9C3"
        ></path>{" "}
        <path
          d="M44.0493 27.9396C42.727 27.9396 41.8693 26.8471 41.8693 25.11C41.8693 23.3907 42.727 22.3162 44.0493 22.3162C44.6211 22.3162 45.0857 22.5669 45.4252 22.943L45.9255 22.3879C45.5324 21.9401 44.8712 21.5461 44.0493 21.5461C42.2624 21.5461 40.958 22.9072 40.958 25.1279C40.958 27.3844 42.2624 28.7097 43.9599 28.7097C44.8712 28.7097 45.5502 28.3336 46.0863 27.7068L45.586 27.1695C45.175 27.6709 44.6925 27.9396 44.0493 27.9396ZM51.1967 26.0054C51.1967 27.4381 50.5713 27.9575 49.7315 27.9575C48.9095 27.9575 48.2841 27.4381 48.2841 26.0054V21.6894H47.4086V25.9875C47.4086 27.9575 48.3913 28.7276 49.7315 28.7276C51.0716 28.7276 52.0365 27.9754 52.0365 25.9875V21.6894H51.1788V26.0054H51.1967ZM58.4692 23.6236C58.4692 22.1909 57.4686 21.6894 56.0749 21.6894H53.9306V28.5843H54.8062V25.6652H56.0391L57.7009 28.5843H58.7015L56.9504 25.5756C57.8438 25.3428 58.4692 24.6981 58.4692 23.6236ZM55.9319 24.9488H54.7883V22.3879H55.9319C57.004 22.3879 57.5937 22.7102 57.5937 23.6236C57.5937 24.519 57.004 24.9488 55.9319 24.9488Z"
          fill="#93A9C3"
        ></path>{" "}
        <path
          d="M61.2391 25.3428H64.0981V24.5907H61.2391V22.4237H64.6163V21.6895H60.3457V28.5843H64.7235V27.8322H61.2391V25.3428Z"
          fill="#93A9C3"
        ></path>
      </g>{" "}
      <defs>
        <clipPath id="clip0">
          <rect
            width="64.4342"
            height="30.9284"
            fill="white"
            transform="translate(0.289062 0.718262)"
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export default SSL;
