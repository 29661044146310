import React from "react";
import "styled-components/macro";
import MasterCard from "../../assets/icons/MasterCard";
import Visa from "../../assets/icons/Visa";
import SSL from "../../assets/icons/SSL";
import Flexbox from "../UI/Flexbox/Flexbox";

const Footer = () => {
  return (
    <Flexbox
      as="footer"
      justify="space-between"
      css={`
        padding: 10px;
      `}
    >
      <MasterCard></MasterCard>
      <Visa></Visa>
      <SSL></SSL>
    </Flexbox>
  );
};

export default Footer;
