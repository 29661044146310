import React from "react";
import styled from "styled-components";

import LoaderSVG from "../../assets/icons/Loader";

const LoaderContainer = styled.div`
  position: absolute;
  z-index: 10;
  background-color: rgba(94, 94, 94, 0.3);
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = () => {
  return (
    <LoaderContainer>
      <LoaderSVG />
    </LoaderContainer>
  );
};

export default Loader;
