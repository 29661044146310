import React from "react";
import Flexbox from "../UI/Flexbox/Flexbox";
import styled from "styled-components";

const HeaderContainer = styled(Flexbox)`
  margin-bottom: 100px;
  color: #57606f;

  & > h1 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  & > p {
    font-size: 12px;
    letter-spacing: 6px;
    text-transform: uppercase;
  }

  @media screen and (min-width: 500px) {
    & > h1 {
      font-size: 40px;
    }

    & > p {
      font-size: 16px;
    }
  }
`;

const Header = () => {
  return (
    <HeaderContainer column alignItems="center" fullWidth>
      <h1>mega.exchange</h1>
      <p>change fast and easy</p>
    </HeaderContainer>
  );
};

export default Header;
